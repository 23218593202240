<template>
  <div class="model-page__operate">
    <div v-for="item in operateImg" :key="item.effect">
      <component
        :is="item.imgName"
        :style="mouseStyle(item.effect)"
        :class="{ active: currentEffect === item.effect }"
        @click="$emit('change', item)"
      ></component>

      <div class="model-page__operate-label">{{ item.label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isFull: Boolean,
    currentEffect: String
  },
  components: {
    PanTool: () => import('@/icons/svg/pan_tool.svg'),
    Orbit: () => import('@/icons/svg/360.svg'),
    ZoomOutMap: () => import('@/icons/svg/zoom_out_map.svg'),
    Ruler: () => import('@/icons/svg/ruler.svg')
  },
  data() {
    return {
      operateImg: [
        {
          imgName: 'PanTool',
          effect: 'reset',
          label: 'Pan'
        },
        {
          imgName: 'Orbit',
          effect: 'rotate',
          label: 'Orbit'
        },
        {
          imgName: 'ZoomOutMap',
          effect: 'full',
          label: 'Zoom'
        },
        {
          imgName: 'Ruler',
          effect: 'ruler',
          label: 'Measure'
        }
      ]
    }
  },
  computed: {
    mouseStyle() {
      return (effect) => {
        switch (effect) {
          case 'full':
            return {
              cursor: this.isFull ? 'zoom-out' : 'zoom-in'
            }
          default:
            return {}
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.model-page__operate {
  position: absolute;
  bottom: 30px;
  display: inline-flex;
  width: auto;
  height: 67.5px;
  margin: 20px auto 0;
  background: #e5e5e5;
  border-radius: 50px;
  padding: 20px;

  > div {
    position: relative;

    svg {
      cursor: pointer;
      display: inline-block;
      transition: all 0.4s;
    }

    svg + svg {
      margin-left: 20px;
    }

    svg.active {
      transform: scale(1.1);
      path {
        fill: var(--color-primary);
      }
    }

    &:hover .model-page__operate-label {
      opacity: 1;
    }

    .model-page__operate-label {
      position: absolute;
      transition: all 0.4s;
      top: -40px;
      opacity: 0;
      left: 10px;
      background: rgb(97, 97, 97, 0.8);
      color: #fff;
      width: 90px;
      padding: 4px 0;
      font-size: 12px;
      border-radius: 4px;
      text-align: center;
      z-index: 99999;
      transform: translateX(-50%);

      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: -9px;
        left: calc(50% - 5px);
        border: 5px solid transparent;
        border-top-color: rgb(97, 97, 97, 0.8);
      }
    }
  }

  > div + div {
    margin-left: 15px;
  }
}
</style>
