/* eslint-disable import/extensions */
/**
 * @author: peng-xiao-shuai
 * @date: 2023-07-14 15:50:10
 * @last Modified by: peng-xiao-shuai
 * @last Modified time: 2023-07-14 15:50:10
 */
import * as THREE from 'three'
import Storage from '@/utils/Storage.js'
// eslint-disable-next-line import/no-unresolved
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader.js'
// eslint-disable-next-line import/no-unresolved
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import fontData from 'three/examples/fonts/helvetiker_bold.typeface.json'
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js'
import {
  acceleratedRaycast,
  computeBoundsTree,
  disposeBoundsTree,
  INTERSECTED,
  NOT_INTERSECTED
} from 'three-mesh-bvh'
import { Loading } from 'element-ui'

export const indexDB = Storage()

THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree
THREE.Mesh.prototype.raycast = acceleratedRaycast
/**
 * 提供 commitId 标识，将代码回滚到该commit可能会看的懂一些
 * commitId 88981a1dd3e2098013898cc5feac100602b7fcf2
 */
const isProperty = (data, key) => data && data[key] && typeof data[key] === 'function'
// 进度条
function onProgress(xhr, name) {
  if (xhr.lengthComputable) {
    const percentComplete = ((xhr.loaded / xhr.total) * 100).toFixed(2)
    this.loading.text = `创建进度 ${percentComplete}%`
    console.log(`创建进度 ${percentComplete}%`)
    if (percentComplete >= 100) {
      console.log(`${name} ${Math.round(percentComplete, 2)}% downloaded`)
      // 加载完成回调
      if (isProperty(this.options, 'loadingCompleted')) {
        this.options.loadingCompleted.apply(this)
      }
    }
  }
}
// 创建文本参数
const textOptions = {
  font: new FontLoader().parse(fontData),
  size: 0.3,
  height: 0.02
}

const CIRCLE_LINE_COLOR = '#1F97F5'
// 图片参考 src/assets/Float32Array.jpg
const VERTICES = [
  // eslint-disable-next-line prettier/prettier
  -0.3, -0.3, 0,
  // eslint-disable-next-line prettier/prettier
  4.5, -0.3, 0,
  // eslint-disable-next-line prettier/prettier
  4.5, 0.6, 0,

  // eslint-disable-next-line prettier/prettier
  4.5, 0.6, 0,
  // eslint-disable-next-line prettier/prettier
  -0.3, 0.6, 0,
  // eslint-disable-next-line prettier/prettier
  -0.3, -0.3, 0
]
/**
 * 创建文字
 * @author peng-xiao-shuai
 * @param {array} position 参考点
 * @param {object} option 参数
 * @param {array} vertices Float32Array 参数可以改变几何大小
 */
function createReferencePointText(
  position,
  itemGroup,
  option,
  // 图片参考 src/assets/Float32Array.jpg
  vertices = VERTICES
) {
  // 文字背景
  const planeGeometry = new THREE.PlaneGeometry(4, 1).toNonIndexed()

  // set 位置
  if (vertices) {
    planeGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(vertices), 3))
  }

  const planeMesh = new THREE.Mesh(
    planeGeometry,
    new THREE.MeshBasicMaterial({
      color: option.bgColor || 'black',
      opacity: option.opacity || 0.5,
      transparent: true,
      side: THREE.DoubleSide
    })
  )
  // console.log(position, 'position')
  planeMesh.position.set(...position)
  planeMesh.position.x += 0.6
  planeMesh.position.y += -0.2
  // planeMesh.rotation.x = Math.PI / 2
  // console.log(planeMesh.position, 'planeMesh')
  // 文字
  const textMesh = new THREE.Mesh(
    new TextGeometry(option.text, { ...textOptions, ...(option.textOptions || {}) }),
    new THREE.MeshBasicMaterial({ color: option.textColor || 'red' })
  )
  textMesh.position.set(...planeMesh.position.clone())
  // textMesh.rotation.x = Math.PI / 2
  // console.log(textMesh.position, 'planeMesh')

  itemGroup.add(planeMesh)
  itemGroup.add(textMesh)

  return itemGroup
  // 这里的this不是构造函数的this，而是群组的this
}

// 模型以及参考点偏移角度
// const POSITION = [0, -7, 0]

/**
 * 创建圆形参考点
 * @author peng-xiao-shuai
 * @param {array} position 点坐标
 * @param {object} option
 */
function createCirclePoint(position, option = {}) {
  // 创建群组包含 text 和 circle
  const itemGroup = new THREE.Group()
  itemGroup.name = option.groupName || 'circleGroup'
  const circleMesh = new THREE.Mesh(
    new THREE.SphereGeometry(option.radius || 0.2, option.segments || 32),
    // 创建材质
    new THREE.MeshBasicMaterial({ color: option.color || '#ED2939' })
  )
  circleMesh.name = option.name
  circleMesh.userData = {
    ...option.userData,
    // 初始颜色
    color: circleMesh.material.color.clone()
  }
  itemGroup.position.set(position[0], position[1], position[2])
  itemGroup.add(circleMesh)

  return { circleMesh, itemGroup }
}

// 设置圆点大小
function setCircleScale(arr = ['create-blue-circle', 'blackGroup', 'circleGroup']) {
  if (this.effect === 'full' || this.effect === 'ruler' || this.effect === '') {
    const modelGroup = this.group.getObjectByName(this.modelGroupName)
    const scale = 1 * (this.camera.position.z / this.initCameraZ)
    arr.forEach((name) => {
      modelGroup.getObjectsByProperty('name', name).forEach((item) => {
        item.scale.set(scale, scale, scale)
      })
    })
  }
}

/**
 * 创建 3d 模型
 */
export default class CreateThreeModel {
  constructor(options = {}) {
    this.options = options
    // 参考点坐标
    this.referencePoint = []
    // 当前模型名称
    this.modelGroupName = null
    this.width = options.width || window.innerWidth
    this.height = options.height || window.innerHeight
    // 场景
    this.scene = new THREE.Scene()
    this.scene.background = new THREE.Color('#ffffff')

    this.renderer = new THREE.WebGLRenderer()
    // // 轨道控制器
    this.controls = null
    // 相机
    this.camera = new THREE.PerspectiveCamera(55, this.width / this.height)
    // 定义初始化相机Z位置
    this.initCameraZ = 0
    this.effect = ''

    // 创建射线投射器
    this.raycaster = new THREE.Raycaster()

    // Dom实例
    this.container = null
    this.isRender = false

    this.sphereCollision = null
    // // 创建一个空的材质数组,后续合并模型
    // this.materials = []
    // this.geometry = []

    // 模型群组，包含所有模型，例如 '2022 nov 19', '2022 dev 20' 等模型
    this.group = new THREE.Group()
    this.group.name = 'model'
    // this.group.position.y = -7

    // 动画实例，页面部分隐藏时模型停止
    this.animationId = null
    // 如果创建不在调用 animate
    this.isAnima = false
    // 是否可以绘制线段
    this.isDrawLine = true
    // 加载状态loading
    this.loading = null

    // 鼠标是否按下
    this.isMouseDown = false
    // 缓存上一次鼠标位置，判断是否加或减
    this.mousemoveCache = {
      x: 0,
      y: 0
    }
  }

  createLoading() {
    this.loading = Loading.service({
      lock: true,
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
  }

  // 初始化
  init() {
    if (!this.options.id) console.error('未传递id')
    this.container = document.getElementById(this.options.id)
    this.createLoading()
    // 开始创建回调函数
    if (isProperty(this.options, 'startCreate')) this.options.startCreate.apply(this)

    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(this.width, this.height)
    this.container.appendChild(this.renderer.domElement)
    this.camera.position.set(0, 8, 28)

    // 轨道控制器
    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    // this.controls.maxPolarAngle = Math.PI / 2
    // this.controls.minPolarAngle = Math.PI / 2
    this.controls.maxDistance = 350
    this.controls.minDistance = 5
    this.controls.enableZoom = true
    this.controls.enableRotate = true
    this.controls.target = new THREE.Vector3(0, 8, 0)
    this.initCameraZ = this.camera.position.z
    // this.controls.enabled = false
    this.controls.update()

    // 辅助线
    if (process.env.NODE_ENV === 'development') {
      const axesHelper = new THREE.AxesHelper(500)
      this.scene.add(axesHelper)
    }

    this.sphereCollision = new THREE.Mesh(
      new THREE.SphereGeometry(0.1, 32, 32),
      new THREE.MeshBasicMaterial({
        color: 'red',
        opacity: 0.9,
        transparent: true
      })
    )
    this.sphereCollision.visible = false
    this.scene.add(this.sphereCollision)

    // 光源
    const pointLight = new THREE.PointLight('white', 2.5, 500)
    this.camera.add(pointLight)
    this.scene.add(this.camera)
    this.scene.name = this.options.id

    this.scene.add(this.group)

    this.animate()
  }

  resetData() {
    // this.materials = []
    // this.geometry = []
    this.isRender = false
  }

  /**
   * 创建模型
   * @author peng-xiao-shuai
   * @param { string } name 模型地址
   * @param { string } groupName 模型群组名称。模型将添加在这个群组下面
   * @param { number } length 总共有多少个模型需要加载
   * @param { function } cb 加载完成回调
   */
  createPLYModel(name, groupName, cb) {
    // 创建模型回调
    if (isProperty(this.options, 'startCreateModel')) this.options.startCreateModel.apply(this)

    new PLYLoader().load(
      name,
      (geometry) => {
        // BVH Mesh creation
        const indices = []
        const bvhGeometry = geometry.clone()
        const verticesLength = bvhGeometry.attributes.position.count
        for (let i = 0, l = verticesLength; i < l; i += 1) {
          indices.push(i, i, i)
        }

        bvhGeometry.setIndex(indices)
        const bvhMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 })
        const bvhMesh = new THREE.Mesh(bvhGeometry, bvhMaterial)
        bvhMesh.rotation.x = -(Math.PI / 2)
        bvhMesh.updateMatrixWorld()
        bvhMesh.geometry.computeBoundsTree({ mode: undefined })
        bvhMesh.name = 'bvh'
        // const helper = new MeshBVHVisualizer(bvhMesh, 10)

        // 创建材质并设置顶点颜色属性
        const material = new THREE.PointsMaterial({
          size: 0.01
        })
        material.vertexColors = geometry.hasAttribute('color')

        // 创建 Points 并应用材质
        const Points = new THREE.Points(geometry, material)
        Points.name = 'points'
        Points.rotation.x = -(Math.PI / 2)
        // Points.position.set(...POSITION)
        if (!this.isRender) {
          // 在创建一个群组用于包含参考点和singleMergeMesh
          const wrapGroup = new THREE.Group()
          // 创建一个新的包围盒
          const boundingBox = new THREE.Box3()
          // 计算模型的包围盒
          boundingBox.setFromObject(Points)
          // 存储模型长宽高
          wrapGroup.userData.size = {
            h: (boundingBox.max.y - boundingBox.min.y).toFixed(2),
            w: (boundingBox.max.x - boundingBox.min.x).toFixed(2),
            l: (boundingBox.max.z - boundingBox.min.z).toFixed(2)
          }
          // 以高度为基准换算每米多少距离
          wrapGroup.userData.size.m = this.options.overallHeight / wrapGroup.userData.size.h
          // console.log(wrapGroup.userData.size)
          wrapGroup.name = groupName
          wrapGroup.userData.cacheGroup = []
          wrapGroup.add(Points)
          wrapGroup.add(bvhMesh)
          // wrapGroup.add(helper)

          this.group.add(wrapGroup)
          this.isRender = true
          if (cb && typeof cb === 'function') cb.apply(this, [wrapGroup])
          indexDB.set(name, wrapGroup.toJSON())
          // 渲染完成回调
          if (isProperty(this.options, 'renderCompleted')) {
            // 使用setTimeout 创建宏任务，等待渲染宏任务执行完成，在渲染任务完成之后会立即进入，
            setTimeout(() => {
              this.options.renderCompleted.apply(this)
            })
          }
        }
      },
      (e) => onProgress.apply(this, [e, name]),
      (err) => {
        if (isProperty(this.options, 'loaderError')) {
          this.options.loaderError.apply(this, [err, name])
        }
        console.log(err, name)
      }
    )
  }

  /**
   * 获取indexedDB数据库中数据
   */
  getIndexedDBData(key, cb) {
    return new Promise((resolve, reject) => {
      this.loading.text = '读取缓存中...'
      indexDB.get(key, (data) => {
        if (!data) {
          reject()
        } else {
          const loader = new THREE.ObjectLoader()
          loader.parseAsync(data).then((json) => {
            json.getObjectByName('bvh').geometry.computeBoundsTree({ mode: undefined })

            this.group.add(json)
            this.isRender = true

            if (cb && typeof cb === 'function') cb.apply(this, [json])

            // 渲染完成回调
            if (isProperty(this.options, 'renderCompleted')) {
              // 使用setTimeout 创建宏任务，等待渲染宏任务执行完成，在渲染任务完成之后会立即进入，
              setTimeout(() => {
                this.options.renderCompleted.apply(this)
              })
            }
          })
        }
      })
    })
  }

  /**
   * 创建参考点
   */
  createReferencePoint(modelGroupName, option = {}) {
    this.referencePoint = option.referencePoint

    // 创建参考点群组
    const referencePointGroup = new THREE.Group()
    referencePointGroup.name = 'referencePoint'
    // 循环创建圆点坐标
    this.referencePoint.forEach((position, index) => {
      const p = [...position]
      const { circleMesh, itemGroup } = createCirclePoint(p, {
        ...option,
        name: 'circle',
        color: index > 2 ? option.color : '#08AF23',
        userData: {
          name: `Pt_${index}`
        }
      })

      // 改变this指向传递 圆形mesh
      if (index > 2) {
        // 偏差值（单位毫米）
        let distanceNumber = 0 // 比较老的点位获取差异
        if (this.options.oldReferencePoint) {
          const { m } = this.group.getObjectByName(this.modelGroupName).userData.size
          const currentOldPosition = this.options.oldReferencePoint[index]
          ;[0, 1, 2].forEach((number) => {
            distanceNumber += Math.abs(position[number] - currentOldPosition[number])
          })

          distanceNumber = Number(distanceNumber * m * 1000).toFixed(2)
        } // 文字

        const TEXT = `Pt ${index}: ${position
          .map((item) => Math.floor(item * 100) / 100)
          .join(',')} ${distanceNumber ? `(distance:${distanceNumber}mm)` : ''}`

        createReferencePointText(
          circleMesh.position.clone(),
          itemGroup,
          {
            textColor: 'white',
            text: TEXT
          },
          distanceNumber
            ? VERTICES.map((vp, i) => {
                // vp 表示 VERTICES_position i表示 index
                // TEXT - 负号超过2个 TEXT 长度就是 41
                return i % 3 === 0 && i <= 9 && i > 0 ? vp + (TEXT.length <= 40 ? 3.4 : 3.8) : vp
              })
            : VERTICES
        )
        itemGroup.rotation.x = Math.PI / 2
      }

      referencePointGroup.add(itemGroup)
    })
    referencePointGroup.rotation.x = -Math.PI / 2
    // referencePointGroup.position.set(...POSITION)

    const modelGroup = this.group.getObjectByName(modelGroupName)
    if (modelGroup) {
      // 创建线段
      const line = new THREE.Line(
        new THREE.BufferGeometry().setFromPoints([
          new THREE.Vector3(0, 0, 0),
          new THREE.Vector3(0, 0, 0)
        ]),
        new THREE.LineBasicMaterial({
          color: CIRCLE_LINE_COLOR
        })
      )
      line.name = 'line'
      // 设置不可见
      line.visible = false
      modelGroup.add(line)
      modelGroup.add(referencePointGroup)

      // 在第一次执行时不会执行 this.controls.addEventListener
      // 这里手动执行
      setCircleScale.apply(this, [['circleGroup']])

      this.controls.addEventListener('end', () => {
        setCircleScale.apply(this)
      })
    } else {
      console.error(`没有找到${modelGroupName}名称的群组`)
    }
  }

  /**
   * 判断是否点击某个几何
   * @param {{x: number, y: number, color?: string}} options
   */
  clickObject3D(option) {
    // 更新射线的起点和方向
    const modelGroup = this.group.getObjectByName(this.modelGroupName)
    const { cacheGroup } = modelGroup.userData
    const line = modelGroup.getObjectByName('line')

    this.computedRayTouchPoint(option, (p, bvhMesh) => {
      // 现将所有的颜色设置回初始颜色，在if 中下面在单独设置颜色
      const point = new THREE.Vector3().copy(p).applyMatrix4(bvhMesh.matrixWorld)
      if (cacheGroup.length === 2) {
        cacheGroup.splice(0, cacheGroup.length)
        const blackGroup = modelGroup.getObjectByName('blackGroup')
        const blurCircles = modelGroup.getObjectsByProperty('name', 'create-blue-circle')
        // 删除黑色群组
        modelGroup.remove(blackGroup)
        // 删除蓝色圆点
        modelGroup.remove(...blurCircles)

        console.log(blackGroup, modelGroup)
        blurCircles.forEach((obj) => {
          // 销毁相关资源
          if (obj.geometry.dispose) obj.geometry.dispose()
          if (obj.material.dispose) obj.material.dispose()
        })
        blackGroup.children.forEach((obj) => {
          // 销毁相关资源
          if (obj.geometry.dispose) obj.geometry.dispose()
          if (obj.material.dispose) obj.material.dispose()
        })
      }

      // 不相同的两个点进行比较
      // if (!cacheGroup[0] || cacheGroup[0].uuid !== object.uuid) cacheGroup.push(object.parent)

      // 创建蓝色点群组
      // eslint-disable-next-line no-constant-condition
      if (true) {
        // 避免命名重复
        const { circleMesh, itemGroup } = createCirclePoint([point.x, point.y, point.z], {
          name: 'create-blue-circle',
          color: CIRCLE_LINE_COLOR
        })
        circleMesh.position.set(...point)
        modelGroup.add(circleMesh)
        // 这里不需要群组，删除群组
        itemGroup.remove()
        cacheGroup.push(circleMesh)

        setCircleScale.apply(this, [['create-blue-circle']])
      }

      // 创建黑色点，黑色线段，背景以及文字
      if (cacheGroup.length > 1) {
        // 0.1 为圆点半径
        // 设置终点
        line.geometry.attributes.position.setXYZ(1, point.x, point.y, point.z - 0.05)
        line.geometry.attributes.position.needsUpdate = true

        // 禁用鼠标移动线断顶点跟随移动
        this.isDrawLine = false

        const status = cacheGroup[0].position.y - cacheGroup[1].position.y > 0

        // 比较两个坐标，获取y轴大的圆点
        const [positionX, positionY, positionZ] = ['x', 'y', 'z'].map(
          (key) => (cacheGroup[0].position[key] - cacheGroup[1].position[key]) / 2
        )

        // 让黑色点在两个蓝色点中间
        const position = status ? cacheGroup[0].position.clone() : cacheGroup[1].position.clone()
        position.x -= status ? positionX : -positionX
        position.y -= Math.abs(positionY)
        position.z -= status ? positionZ : -positionZ
        const { itemGroup } = createCirclePoint([position.x, position.y, position.z], {
          groupName: 'blackGroup',
          name: 'black-circle',
          color: 'black',
          userData: {}
        })

        // 获取世界坐标内距离
        // const height = cacheGroup[0].position.distanceTo(cacheGroup[1].position)
        // 换算世界坐标为m
        const [modelX, modelY, modelZ] = ['x', 'y', 'z'].map((key) => {
          return Math.abs(
            (cacheGroup[0].position[key] - cacheGroup[1].position[key]) * modelGroup.userData.size.m
          ).toFixed(2)
        })
        const distance = Number(modelX) + Number(modelY) + Number(modelZ)
        // 常量，后面不会修改
        const numbers = [-15, 12]
        createReferencePointText(
          [numbers[0], 0, 0],
          itemGroup,
          {
            textColor: 'white',
            opacity: 1,
            text: `X: ${modelX}m, Y: ${modelY}m, Z: ${modelZ}m, Distance: ${distance.toFixed(2)}m`,
            textOptions: {
              size: 0.4
            }
          },
          [
            // eslint-disable-next-line prettier/prettier
            -0.3,
            -0.3,
            0,
            // eslint-disable-next-line prettier/prettier
            distance > 9 ? numbers[1] + 0.5 : numbers[1],
            -0.3,
            0,
            // eslint-disable-next-line prettier/prettier
            distance > 9 ? numbers[1] + 0.5 : numbers[1],
            0.6,
            0,

            // eslint-disable-next-line prettier/prettier
            distance > 9 ? numbers[1] + 0.5 : numbers[1],
            0.6,
            0,
            // eslint-disable-next-line prettier/prettier
            -0.3,
            0.6,
            0,
            // eslint-disable-next-line prettier/prettier
            -0.3,
            -0.3,
            0
          ]
        )

        // 创建黑色线段
        const blackLine = new THREE.Line(
          new THREE.BufferGeometry().setFromPoints([
            new THREE.Vector3(0, 0, 0),
            new THREE.Vector3(numbers[0] + numbers[1] + 0.6, 0, 0)
          ]),
          new THREE.LineBasicMaterial({
            color: 'black'
          })
        )
        blackLine.name = 'blackLine'
        itemGroup.add(blackLine)
        modelGroup.add(itemGroup)

        setCircleScale.apply(this, [['blackGroup']])
      } else {
        this.isDrawLine = true

        // 设置线段起始点
        line.visible = true
        line.geometry.attributes.position.setXYZ(0, point.x, point.y, point.z)
        line.geometry.attributes.position.setXYZ(1, point.x, point.y, point.z)
        line.geometry.attributes.position.needsUpdate = true
      }
    })
  }

  // 计算射线是否触碰到某个点
  computedRayTouchPoint(option, cb) {
    this.raycaster.setFromCamera(this.getMouseVector(option.x, option.y), this.camera)
    const bvhMesh = this.group.getObjectByName(this.modelGroupName).getObjectByName('bvh')

    const inverseMatrix = new THREE.Matrix4()
    inverseMatrix.copy(bvhMesh.matrixWorld).invert()
    this.raycaster.ray.applyMatrix4(inverseMatrix)
    // 设置射线大小，默认为 1，改为 0.005
    this.raycaster.params.Points.threshold = 0.002

    const { threshold } = this.raycaster.params.Points
    const localThreshold = threshold / ((bvhMesh.scale.x + bvhMesh.scale.y + bvhMesh.scale.z) / 3)
    const localThresholdSq = localThreshold * localThreshold

    const { ray } = this.raycaster
    let closestDistance = Infinity
    bvhMesh.geometry.boundsTree.shapecast({
      boundsTraverseOrder: (box) => {
        // traverse the closer bounds first.
        return box.distanceToPoint(ray.origin)
      },
      intersectsBounds: (box, isLeaf, score) => {
        // if we've already found a point that's closer then the full bounds then
        // don't traverse further.
        if (score > closestDistance) {
          return NOT_INTERSECTED
        }

        box.expandByScalar(localThreshold)
        return ray.intersectsBox(box) ? INTERSECTED : NOT_INTERSECTED
      },
      intersectsTriangle: (triangle) => {
        const distancesToRaySq = ray.distanceSqToPoint(triangle.a)
        if (distancesToRaySq < localThresholdSq) {
          // track the closest found point distance so we can early out traversal and only
          // use the closest point along the ray.
          const distanceToPoint = ray.origin.distanceTo(triangle.a)
          if (distanceToPoint < closestDistance) {
            closestDistance = distanceToPoint
            if (cb && typeof cb === 'function') cb(triangle.a, bvhMesh)
            // sphereCollision.position.copy(triangle.a).applyMatrix4(bvhMesh.matrixWorld)
            // sphereCollision.visible = true
          }
        }
      }
    })
  }

  /**
   * 修改线段
   * @param {{x: number, x: number}} option
   * @deprecated 由于平移后会影响线段位置不对故此废弃
   */
  setLine(option) {
    if (!this.isDrawLine || !option.x || !option.y) return

    const mouseVector = new THREE.Vector3()
    const { x, y } = this.getMouseVector(option.x, option.y)
    mouseVector.x = x
    mouseVector.y = y
    mouseVector.z = 0.5
    // unproject 方法将归一化设备坐标转换为相机坐标系下的坐标，并将其存储在 mouseVector 对象中。
    mouseVector.unproject(this.camera)
    if (this.group.getObjectByName(this.modelGroupName)) {
      const line = this.group.getObjectByName(this.modelGroupName).getObjectByName('line')
      if (line) {
        line.geometry.attributes.position.setXYZ(1, mouseVector.x, mouseVector.y, mouseVector.z)
        line.geometry.attributes.position.needsUpdate = true
      }
    }
  }

  /**
   * 鼠标位置转换世界坐标
   * @param {number} x
   * @param {number} y
   */
  getMouseVector(x, y) {
    if (!x || !y) {
      console.error('x, y 不能为空')
      return undefined
    }

    // 计算鼠标点击位置的归一化设备坐标（NDC）
    const mouseVector = new THREE.Vector2()
    // 归一化坐标它是一个标准化的坐标空间，范围是 [-1, 1]
    mouseVector.x = (x / this.renderer.domElement.clientWidth) * 2 - 1
    mouseVector.y = -(y / this.renderer.domElement.clientHeight) * 2 + 1
    return mouseVector
  }

  /**
   * 平移
   * @author peng-xiao-shuai
   */
  pan(x, y) {
    const baseValue = 0.1
    if (!this.isMouseDown || !x || !y) return
    if (this.mousemoveCache.x - x !== 0) {
      this.camera.position.x -= this.mousemoveCache.x - x > 0 ? -baseValue : baseValue
      this.controls.target.x -= this.mousemoveCache.x - x > 0 ? -baseValue : baseValue
    }
    if (this.mousemoveCache.y - y !== 0) {
      this.camera.position.y -= this.mousemoveCache.y - y > 0 ? baseValue : -baseValue
      this.controls.target.y -= this.mousemoveCache.y - y > 0 ? baseValue : -baseValue
    }
    this.controls.update()
    this.mousemoveCache.x = x
    this.mousemoveCache.y = y
  }

  /**
   * 导出模型
   * @author peng-xiao-shuai
   */
  exportModel(filename) {
    this.createLoading()
    this.loading.text = '导出中...'
    // console.log(filename, '导出')
    console.log(this.modelGroupName, 'this.modelGroupName')
    console.log(this.group.getObjectByName(this.modelGroupName))
    setTimeout(() => {
      const exporter = new GLTFExporter()
      exporter.parse(
        this.group.getObjectByName(this.modelGroupName),
        (objectData) => {
          this.loading.text = '导出完成'
          // 下载导出的模型文件
          const link = document.createElement('a')
          const url = URL.createObjectURL(
            new Blob([JSON.stringify(objectData)], { type: 'text/plain' })
          )
          link.href = url
          link.download = `${filename}.gltf`
          link.click()
          window.URL.revokeObjectURL(url)
          this.loading.close()
        },
        { binary: false }
      )
    }, 400)
  }

  /**
   * 重置轨道控制器
   */
  resetRotate() {
    this.controls.enabled = false
    this.controls.reset()
  }

  reset() {
    this.renderer.setSize(this.width, this.height)
    this.camera.aspect = this.width / this.height
    this.camera.updateProjectionMatrix()
  }

  /**
   * 渲染模型
   */
  render() {
    this.controls.update()
    // 创建模型回调
    this.renderer.render(this.scene, this.camera)
  }

  // 动画帧执行
  animate(bol = true) {
    if (this.isAnima && bol) return
    this.isAnima = true
    // console.log(this.animationId)
    this.animationId = requestAnimationFrame(this.animate.bind(this, false))
    this.render()
  }

  // 停止
  stopAnimate() {
    cancelAnimationFrame(this.animationId)
    this.isAnima = false
  }
}
